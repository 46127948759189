import { useStaticQuery, graphql } from 'gatsby'

const Query = () => {
  const query = useStaticQuery(graphql`
      query {
        phone: file(relativePath: { eq: "phone.jpg" }) {
          ...fluidImage
        }
        paper: file(relativePath: { eq: "paper.jpg" }) {
          ...fluidImage
        }
        office: file(relativePath: { eq: "office.jpg" }) {
          ...fluidImage
        }
        code: file(relativePath: { eq: "code.jpg" }) {
          ...fluidImage
        }
      }
    `)

  return query
}

export default Query

export const fluidImage = graphql`
fragment fluidImage on File {
  childImageSharp {
    fluid(maxWidth: 700, maxHeight: 475, quality: 100) {
      ...GatsbyImageSharpFluid
    }
  }
}
`;
