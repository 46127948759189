import React from "react"
import styled from "styled-components"

import Title from "./title"
import Headline from "./headline"
import Image from "./image"
import Body from "./body"

const CardMain = styled.div`
  display: grid 1fr; 
  margin-top: 1em;
  margin-bottom: 8.75em;
  @media (min-width: 1024px) {
    display: grid; 
    grid-template-columns: repeat(auto-fill, minmax(30em, 1fr));
  }
`


const Main = ({ query, card, isLeft }) => {
  return (
    <>
      <CardMain>
        <Title title={card.title} />
        <Headline headline={card.headline} />
        <Image query={query} filename={card.image} isLeft={isLeft} />
        <Body body={card.body} link={card.link} isLeft={isLeft} />
      </CardMain>
    </>
  )
}

export default Main
