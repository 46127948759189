import React from "react"
import styled from "styled-components"

const HeadlineTitle = styled.h3`
  grid-column: 1;
  grid-row: 2;
  margin: 0;
  margin-bottom: 2rem;
  font-size: 1.5em;
  font-weight: 500;
  @media (min-width: 600px) {
    grid-column: 1 / span 2;
  }
`

const Headline = ({ headline }) => (
  <HeadlineTitle>{headline}</HeadlineTitle>
)

export default Headline
