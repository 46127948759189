import React from "react"
import styled from "styled-components"

const CardTitle = styled.h2`
  grid-column: 1;
  grid-row: 1;
  margin: 0;
  margin-bottom: 1rem;
  color: #67676e;
  letter-spacing: 0.125em;
  text-transform: uppercase;
  font-size: 1em;
  font-weight: 400;
  @media (min-width: 600px) {
    grid-column: 1 / span 2;
  }
`

const CardAnchor = styled.a`
  display: block;
  position: relative;
  top: -15em;
  visibility: hidden;
`

const Title = ({ title }) => (
  <>
    <CardAnchor id={title} />
    <CardTitle>{title}</CardTitle>
  </>
)

export default Title
