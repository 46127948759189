import React from "react"

import data from "../data/index.yaml"
import images from "../components/images/index"

import Layout from "../components/layout"
import SEO from "../components/seo"
import CallToAction from "../components/call-to-action/main"
import Card from "../components/card/main"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <CallToAction body={data.callToAction}/>
    {
      data.cards.map((cards, index) =>
        <Card key={`page_card_${index}`} query={images} card={cards} isLeft={(index & 1)} />
      )
    }
  </Layout>
)

export default IndexPage
