import React from "react"
import styled from "styled-components"

import Body from "./body"
import ProjectButton from "./projectButton"

const CallToAction = styled.div`
  margin: 0;
  margin-top: 4em;
  margin-bottom: 4em;
  @media (min-width: 1024px) {
    display: grid;
  }
`
const CallToActionContent = styled.div`
  margin-top: 2rem;
  margin-bottom: 8rem;
  @media (min-width: 1024px) {
    margin-top: 0;
    padding-left: 18rem;
    padding-right: 18rem;
    padding-bottom: 2rem;
  }
`

const Main = ({ body }) => {
  return (
    <CallToAction>
      <CallToActionContent>
        <Body body={body} />
        <div style={{ marginTop: `2rem`}}>
          <ProjectButton />
        </div>
      </CallToActionContent>
    </CallToAction>
  )
}

export default Main
