import React from "react"
import styled from "styled-components"

import Testimonial from "./testimonial"
import Link from "../base/link"

const CardBody = styled.div`
  grid-column: 1;
  grid-row: 4;
  margin: 0;
  @media (min-width: 600px) {
    grid-column: ${props => props.isLeft ? '2' : '1'};
    align-self: center;
    justify-self: center;
    margin: 5rem;
  }
`

const BodyTitle = styled.h3`
  margin: 0;
  margin-top: 1rem;
  margin-bottom: 1rem;
  line-height: 2em;
  font-size: 1.2em;
  font-weight: 500;
`

const BodyContent = styled.p`
  line-height: 1.5em;
  font-size: 1.2em;
`

const CardLink = styled.div`
  margin-top: 3rem;
  font-size: 1.2em;
  float: right;
  color: unset;
  text-decoration: underline;
`
const hooks = ['Read', 'Find out', 'Learn', 'Uncover'];

const Body = ({ body, link, isLeft }) => {
  // const cardLink = {uri: `${link}`, title: `${hooks[0]} more about our ${link}`};
  return (
  <CardBody isLeft={isLeft}>
    {body.map( (item, index) =>
      <div key={`card_body_${item.title}_${index}`}>
          <BodyTitle>{item.title}</BodyTitle>
          <BodyContent>{item.description}</BodyContent>
          {item.testimonial
            ? <Testimonial testimonial={item.testimonial} />
            : null
          }
      </div>
    )}
    {
    // <CardLink>
    //   <Link props={cardLink} />
    // </CardLink>
    }
  </CardBody>
  )
}

export default Body
