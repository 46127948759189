import React from "react"
import styled from "styled-components"

const CardBody = styled.div`
`

const CardHeadline = styled.h1`
  line-height: 1.2em;
  @media (min-width: 600px) {
    line-height: 1.5em;
  }
`

const CardDescription = styled.p`
  font-size: 1.2em;
  font-weight: 400;
  line-height: 1.5em;
`

const Body = ({ body }) => (
  <CardBody>
    <>
      <CardHeadline>{body.headline}</CardHeadline>
      <CardDescription>{body.description}</CardDescription>
    </>
  </CardBody>
)

export default Body
