import React from "react"
import styled from "styled-components"
import Img from 'gatsby-image'

const CardImage = styled(Img)`
  grid-column: 1;
  grid-row: 3;
  padding: 1em;
  z-index: -1;
  @media (min-width: 600px) {
    grid-column: ${props => props.isLeft ? '1' : '2'};
    grid-row: 3 / span 4;
  }
`

const Image = ({ query, filename, isLeft }) => {
  return <CardImage isLeft={isLeft} as={Img} fluid={query()[filename].childImageSharp.fluid} />
}

export default Image

