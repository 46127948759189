import React from "react"
import styled from "styled-components"

const TestimonialBlockquote = styled.blockquote`
  margin: 0;
`

const TestimonialContent = styled.p`
  padding: 1em;
  background: #eee;
  border-radius: 1em;
  ::before {
    content: '\\201C';
  }
  ::after {
    content: '\\201D';
  }
`

const Testimonial = ({ testimonial }) => {
  return (
    <>
      <TestimonialBlockquote>
        <TestimonialContent>
          {testimonial.content}
        </TestimonialContent>
        <footer style={{ paddingLeft: `1em`}}>-- {testimonial.person}, <cite>{testimonial.job}</cite></footer>
      </TestimonialBlockquote>
    </>
  )
}

export default Testimonial
